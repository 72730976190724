.wrap {
    overflow: hidden;
    position: absolute;
    height: calc(100%- 16px);
    width: calc(100%- 16px);
    padding: 100px 8px 8px 8px;
}

.bg {
    opacity: 0.3;
    position: absolute;
    
    left: 8;
    top: 0;
    width: calc(100%- 16px);
    height: 100%;
}

.content {
    position: relative;
    margin-bottom: 15px;
}
.footer {
    position:absolute;
left:8;
bottom:0;
right:8;
}